import React from 'react';
import { Upload } from 'lucide-react';

const ImageUploader = ({ getRootProps, getInputProps, isDragActive, dragCount, handleDragEnter, handleDragLeave }) => {
    return (
        <div
            {...getRootProps()}
            className="relative"
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
        >
            <div className={`
                border-2 border-dashed rounded-lg p-8 text-center cursor-pointer transition-all duration-300
                ${dragCount > 0 ? 'border-blue-500 bg-blue-50 scale-102' : 'border-gray-300 hover:border-blue-400'}
            `}>
                <input {...getInputProps()} />
                <div className="space-y-3">
                    <Upload className={`mx-auto h-12 w-12 transition-colors duration-300 ${dragCount > 0 ? 'text-blue-500' : 'text-gray-400'}`} />
                    <p className="text-gray-600 font-medium">
                        {isDragActive ? "Drop your image here..." : "Drag and drop your image here"}
                    </p>
                    <p className="text-sm text-gray-500">
                        or click to browse your files
                    </p>
                    <div className="flex items-center justify-center space-x-2 text-xs text-gray-400">
                        <span>Supports: JPG, PNG, WebP</span>
                        <span>•</span>
                        <span>Max size: 5MB</span>
                        <span>•</span>
                        <span>Recommended: 1920x1080</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImageUploader;
