import React, { useState, useEffect } from 'react';
import AdminLayout from '../AdminLayout';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../../../config/axiosConfig';
import { FaSearch, FaFilter } from 'react-icons/fa';

export default function RaceResults() {
  const { rideId } = useParams();
  const queryClient = useQueryClient();
  const [riders, setRiders] = useState([]);
  const [editingResult, setEditingResult] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterCategory, setFilterCategory] = useState('all');

  const { data: rideDetails } = useQuery({
    queryKey: ['rideDetails', rideId],
    queryFn: () => axios.get(`/rides/${rideId}`).then(res => res.data),
    staleTime: 1000 * 60 * 5,
  });

  useEffect(() => {
    if (rideDetails?.registeredRiders) {
      setRiders(rideDetails.registeredRiders);
    }
  }, [rideDetails]);

  const mutation = useMutation({
    mutationFn: async ({ userId, result, rank, totalTime }) => {
      return axios.post(`/rides/${rideId}/riders/${userId}/result`, {
        result,
        rank: result === 'Finished' ? parseInt(rank, 10) : null,
        totalTime: result === 'Finished' ? totalTime : null,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['rideDetails', rideId]);
      setEditingResult(null);
    },
  });

  const filteredRiders = riders.filter((rider) => {
    const matchesSearch =
      !searchTerm ||
      rider.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      rider.urn?.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesCategory =
      filterCategory === 'all' ||
      parseInt(rider.categoryId, 10) === parseInt(filterCategory, 10);

    return matchesSearch && matchesCategory;
  });

  return (
    <AdminLayout title="Race Results Management">
      <div className="space-y-6">
        {/* Search and Filter Controls */}
        <div className="flex flex-wrap gap-4 items-center">
          <div className="flex-grow relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <FaSearch className="text-gray-400" />
            </div>
            <input
              type="text"
              placeholder="Search by name or URN..."
              className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <select
            className="px-4 py-2 border rounded-lg"
            value={filterCategory}
            onChange={(e) => setFilterCategory(e.target.value)}
          >
            <option value="all">All Categories</option>
            {rideDetails?.categories?.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        {/* Results Table */}
        <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">URN</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Category</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Result</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rank</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Time</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredRiders.map((rider) => (
                  <tr key={rider.userId} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{rider.urn}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{rider.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {rideDetails?.categories?.find((cat) => cat.id === rider.categoryId)?.name || 'Uncategorized'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {editingResult?.userId === rider.userId ? (
                        <select
                          value={editingResult.result}
                          onChange={(e) =>
                            setEditingResult({ ...editingResult, result: e.target.value })
                          }
                          className="border rounded px-2 py-1"
                        >
                          <option value="">Select Result</option>
                          <option value="Finished">Finished</option>
                          <option value="DNF">DNF</option>
                          <option value="DNS">DNS</option>
                        </select>
                      ) : (
                        rider.result || '-'
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {editingResult?.userId === rider.userId && editingResult.result === 'Finished' ? (
                        <input
                          type="number"
                          value={editingResult.rank || ''}
                          onChange={(e) =>
                            setEditingResult({ ...editingResult, rank: e.target.value })
                          }
                          className="border rounded w-20 px-2 py-1"
                          min="1"
                        />
                      ) : (
                        rider.rank || '-'
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {editingResult?.userId === rider.userId && editingResult.result === 'Finished' ? (
                        <input
                          type="text"
                          value={editingResult.totalTime || ''}
                          onChange={(e) =>
                            setEditingResult({ ...editingResult, totalTime: e.target.value })
                          }
                          placeholder="HH:MM:SS"
                          className="border rounded px-2 py-1 w-24"
                        />
                      ) : (
                        rider.totalTime || '-'
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      {editingResult?.userId === rider.userId ? (
                        <div className="space-x-2">
                          <button
                            onClick={() =>
                              mutation.mutate({
                                userId: rider.userId,
                                result: editingResult.result,
                                rank: editingResult.rank,
                                totalTime: editingResult.totalTime,
                              })
                            }
                            className="text-green-600 hover:text-green-900"
                          >
                            Save
                          </button>
                          <button
                            onClick={() => setEditingResult(null)}
                            className="text-red-600 hover:text-red-900"
                          >
                            Cancel
                          </button>
                        </div>
                      ) : (
                        <button
                          onClick={() =>
                            setEditingResult({
                              userId: rider.userId,
                              result: rider.result || '',
                              rank: rider.rank || '',
                              totalTime: rider.totalTime || '',
                            })
                          }
                          className="text-blue-600 hover:text-blue-900"
                        >
                          Edit
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}
