import React, { useState, useCallback, memo } from 'react';
import { Trophy, Medal, Award, Star } from 'lucide-react';

const ProfileImage = memo(({ rider, size, onError }) => {
 const [hasError, setHasError] = useState(false);
 const [isLoaded, setIsLoaded] = useState(false);
 const [src, setSrc] = useState(`https://ultrarides-images.s3.ap-south-1.amazonaws.com/profiles/${rider.urn}.jpg`);

 const handleError = useCallback(() => {
   if (!hasError) {
     setHasError(true);
     setSrc(`/api/placeholder/${size}/${size}`);
     onError?.(rider.urn);
   }
 }, [hasError, size, onError, rider.urn]);

 return (
   <div className="relative">
     <div
       className={`
         absolute inset-0 rounded-full 
         ${rider.rank === 1 ? 'bg-gradient-to-r from-[#FFD700] to-[#FDB931]' :
           rider.rank === 2 ? 'bg-gradient-to-r from-[#C0C0C0] to-[#A8A8A8]' :
             'bg-gradient-to-r from-[#CD7F32] to-[#B87333]'}
         animate-pulse
       `}
     />
     <img
       src={src}
       alt={rider.name}
       className={`
         rounded-full relative border-4
         ${rider.rank === 1 ? 'border-[#FFD700]' : 
           rider.rank === 2 ? 'border-[#C0C0C0]' : 
             'border-[#CD7F32]'}
         transition-all duration-300
         ${isLoaded ? 'opacity-100' : 'opacity-0'}
         hover:scale-110
       `}
       style={{
         width: `${size}px`,
         height: `${size}px`,
         objectFit: 'cover'
       }}
       onError={handleError}
       onLoad={() => setIsLoaded(true)}
       loading="lazy"
     />
     {!isLoaded && !hasError && (
       <div
         className="absolute inset-0 bg-secondary/20 rounded-full animate-pulse"
         style={{ width: `${size}px`, height: `${size}px` }}
       />
     )}
   </div>
 );
});

const WinnerCard = memo(({ winner, size }) => {
 const getRankIcon = (rank) => {
   switch (rank) {
     case 1: return <Trophy className="w-8 h-8 text-[#FFD700]" />;
     case 2: return <Medal className="w-8 h-8 text-[#C0C0C0]" />;
     case 3: return <Award className="w-8 h-8 text-[#CD7F32]" />;
     default: return null;
   }
 };

 const getBgColor = (rank) => {
   switch (rank) {
     case 1: return 'bg-[#FFF7E6] border-[#FFD700]';
     case 2: return 'bg-[#F5F5F5] border-[#C0C0C0]';
     case 3: return 'bg-[#FFF1E6] border-[#CD7F32]';
     default: return 'bg-white';
   }
 };

 const getPoints = (rank) => {
   switch (rank) {
     case 1: return 100;
     case 2: return 80;
     case 3: return 60;
     default: return 0;
   }
 };

 return (
   <div className={`
     flex flex-col items-center rounded-xl shadow-lg p-6 
     border-2 hover:scale-105 transition-all duration-300
     ${getBgColor(winner.rank)}
   `}>
     <div className="flex items-center justify-center mb-4">
       {getRankIcon(winner.rank)}
     </div>
     <h3 className="text-xl font-bold text-gray-900 mb-2">{winner.name}</h3>
     <p className="text-sm font-medium text-gray-600 mb-4">Position #{winner.rank}</p>
     <ProfileImage rider={winner} size={size} />
     <div className="mt-6 flex items-center gap-2 bg-white/50 px-4 py-2 rounded-full">
       <Star className="w-5 h-5 text-primary" />
       <span className="font-bold text-primary">{getPoints(winner.rank)} points</span>
     </div>
   </div>
 );
});

const PodiumDisplay = memo(({ winners }) => {
 const getResponsiveSizes = () => ({
   small: { first: 100, second: 90, third: 80 },
   large: { first: 130, second: 110, third: 100 }
 });

 const positions = [
   { rank: 2, offset: 'sm:mt-8' },
   { rank: 1, offset: 'mt-0' },
   { rank: 3, offset: 'sm:mt-16' }
 ];

 return (
   <div className="grid grid-cols-3 gap-6 py-6">
     {positions.map(({ rank, offset }) => {
       const winner = winners.find(w => w.rank === rank);
       if (!winner) return null;

       const sizes = getResponsiveSizes();
       const size = rank === 1 ? sizes.small.first :
         rank === 2 ? sizes.small.second :
           sizes.small.third;

       return (
         <div key={rank} className={`${offset}`}>
           <WinnerCard winner={winner} size={size} />
         </div>
       );
     })}
   </div>
 );
});

const CategoryPodium = ({ categoryResults = [] }) => {
 const categorizedWinners = categoryResults.reduce((acc, result) => {
   if (!acc[result.categoryName]) {
     acc[result.categoryName] = [];
   }
   if (result.rank <= 3) {
     acc[result.categoryName].push(result);
   }
   return acc;
 }, {});

 const categories = Object.keys(categorizedWinners);
 const [activeCategory, setActiveCategory] = useState(categories[0] || null);

 if (categories.length === 0) return null;

 return (
   <div className="bg-gradient-to-b from-gray-50 to-white rounded-xl p-8 border border-gray-200 shadow-lg">
     <div className="mb-8">
       <div className="flex flex-wrap gap-3">
         {categories.map(category => (
           <button
             key={category}
             onClick={() => setActiveCategory(category)}
             className={`
               px-6 py-2.5 rounded-full text-sm font-semibold transition-all duration-300
               ${activeCategory === category 
                 ? 'bg-primary text-white shadow-md ring-2 ring-primary ring-offset-2' 
                 : 'bg-white text-gray-600 hover:bg-gray-100 border-2 border-gray-200'}
             `}
           >
             {category}
           </button>
         ))}
       </div>
     </div>

     {categories.map(category => (
       <div
         key={category}
         className={`
           transition-all duration-500 transform
           ${activeCategory === category ? 'block scale-100' : 'hidden scale-95'}
         `}
       >
         <div className="bg-white rounded-xl p-6 border-2 border-gray-100">
           <h3 className="text-3xl font-bold text-gray-900 mb-8 pb-4 border-b-2 border-gray-100 text-center">
             {category} Champions
           </h3>
           <PodiumDisplay winners={categorizedWinners[category]} />
         </div>
       </div>
     ))}
   </div>
 );
};

export default CategoryPodium;
