import React from 'react';
import ControlPointCard from '../ControlPointCard';

const ControlPointsList = ({
    controlPoints,
    handleInputChange,
    handleRemoveControlPoint,
    totalDistance,
    errors,
    unsavedFields
}) => {
    return (
        <div className="space-y-4">
            {controlPoints.map((point, index) => (
                <ControlPointCard
                    key={index}
                    controlPoint={point}
                    index={index}
                    handleInputChange={handleInputChange}
                    handleRemoveControlPoint={handleRemoveControlPoint}
                    totalPoints={controlPoints.length}
                    totalDistance={totalDistance}
                    errors={errors[index] || {}}
                    isStartOrEnd={index === 0 || index === controlPoints.length - 1}
                    unsavedFields={unsavedFields[index] || {}}
                />
            ))}
        </div>
    );
};

export default ControlPointsList;
