import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from '../../../config/axiosConfig';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import AdminLayout from '../AdminLayout';
import ImagePreview from './ImagePreview';
import ImageUploader from './ImageUploader';
import ImagePreviewWithInfo from './ImagePreviewWithInfo';
import ActionButtons from './ActionButtons';

const CoverImageManager = () => {
    const { rideId } = useParams();
    const queryClient = useQueryClient();
    const [editing, setEditing] = useState(false);
    const [newImage, setNewImage] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [imageInfo, setImageInfo] = useState(null);
    const [isZoomed, setIsZoomed] = useState(false);
    const [dragCount, setDragCount] = useState(0);

    const { data: ride } = useQuery({
        queryKey: ['rideDetails', rideId],
        queryFn: () => axios.get(`/rides/${rideId}`).then(res => res.data),
    });

    useEffect(() => {
        if (ride?.coverImage) {
            setPreviewUrl(ride.coverImage);
        }
    }, [ride]);

    const removeMutation = useMutation({
        mutationFn: async () => {
            return axios.patch(`/rides/${rideId}`, {
                coverImage: '',
                clubId: ride?.clubId
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['rideDetails', rideId]);
            setPreviewUrl('');
            setSuccess('Cover image removed successfully');
            setTimeout(() => setSuccess(''), 3000);
        },
        onError: () => {
            setError('Failed to remove cover image');
            setTimeout(() => setError(''), 3000);
        }
    });

    const handleRemoveImage = () => {
        if (window.confirm('Are you sure you want to remove the cover image?')) {
            removeMutation.mutate();
        }
    };

    useEffect(() => {
        if (newImage) {
            const img = new Image();
            img.src = previewUrl;
            img.onload = () => {
                setImageInfo({
                    width: img.width,
                    height: img.height,
                    aspectRatio: (img.width / img.height).toFixed(2),
                    size: (newImage.size / (1024 * 1024)).toFixed(2)
                });
            };
        }
    }, [newImage, previewUrl]);

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            if (file.size > 5 * 1024 * 1024) {
                setError('Image size must be less than 5MB');
                return;
            }
            setNewImage(file);
            setPreviewUrl(URL.createObjectURL(file));
            setEditing(true);
            setError('');
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/*': ['.jpeg', '.jpg', '.png', '.webp']
        },
        maxSize: 5 * 1024 * 1024,
        multiple: false
    });

    const handleUpload = async () => {
        if (!newImage) return;
        setIsUploading(true);
        setError('');

        try {
            const reader = new FileReader();
            reader.onloadend = async () => {
                const base64Image = reader.result.split(',')[1];

                const interval = setInterval(() => {
                    setUploadProgress(prev => {
                        if (prev >= 90) {
                            clearInterval(interval);
                            return 90;
                        }
                        return prev + 10;
                    });
                }, 200);

                try {
                    const response = await axios.patch(`/rides/${rideId}`, {
                        imageBase64: base64Image,
                        clubId: ride?.clubId
                    });

                    setUploadProgress(100);
                    setTimeout(() => {
                        setEditing(false);
                        setNewImage(null);
                        setIsUploading(false);
                        setUploadProgress(0);
                        setPreviewUrl(response.data.coverImageUrl);
                        setSuccess('Cover image updated successfully');
                        setTimeout(() => setSuccess(''), 3000);
                    }, 500);
                } catch (error) {
                    setError('Failed to upload image. Please try again.');
                    console.error('Error uploading image:', error);
                    clearInterval(interval);
                    setUploadProgress(0);
                    setIsUploading(false);
                }
            };
            reader.readAsDataURL(newImage);
        } catch (error) {
            setError('Failed to process image. Please try again.');
            setIsUploading(false);
        }
    };

    const handleCancel = () => {
        setEditing(false);
        setNewImage(null);
        setPreviewUrl(ride?.coverImage || '');
        setError('');
        setImageInfo(null);
    };

    const handleDragEnter = () => {
        setDragCount(prev => prev + 1);
    };

    const handleDragLeave = () => {
        setDragCount(prev => prev - 1);
    };

    return (
        <AdminLayout title="Cover Image Management">
            <div className="max-w-4xl mx-auto rounded-lg">
                {success && (
                    <div className="mb-4 p-4 bg-green-50 border-l-4 border-green-500 text-green-700">
                        {success}
                    </div>
                )}
                {error && (
                    <div className="mb-4 p-4 bg-red-50 border-l-4 border-red-500 text-red-700">
                        {error}
                    </div>
                )}
                <div className="space-y-6">
                    {!editing ? (
                        <ImagePreview 
                            previewUrl={previewUrl}
                            isZoomed={isZoomed}
                            setIsZoomed={setIsZoomed}
                            handleRemoveImage={handleRemoveImage}
                            setEditing={setEditing}
                        />
                    ) : (
                        <div className="space-y-4">
                            <ImageUploader
                                getRootProps={getRootProps}
                                getInputProps={getInputProps}
                                isDragActive={isDragActive}
                                dragCount={dragCount}
                                handleDragEnter={handleDragEnter}
                                handleDragLeave={handleDragLeave}
                            />

                            {previewUrl && (
                                <>
                                    <ImagePreviewWithInfo
                                        previewUrl={previewUrl}
                                        imageInfo={imageInfo}
                                        isUploading={isUploading}
                                        uploadProgress={uploadProgress}
                                        newImage={newImage}
                                    />
                                    <ActionButtons
                                        handleUpload={handleUpload}
                                        handleCancel={handleCancel}
                                        isUploading={isUploading}
                                        newImage={newImage}
                                    />
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </AdminLayout>
    );
};

export default CoverImageManager;
