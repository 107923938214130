export const calculateCustomControlTimes = (controlPoints, startTime, totalTime) => {
  const totalDistance = controlPoints[controlPoints.length - 1].distance;
  
  return controlPoints.map(point => {
    const proportion = point.distance / totalDistance;
    const timeInMillis = totalTime * 60 * 60 * 1000 * proportion;
    const closeTime = new Date(new Date(startTime).getTime() + timeInMillis);
    return {
      ...point,
      closeTime: closeTime.toISOString()
    };
  });
};

export const calculateCloseTime = (distance, startTime, timeControlType, totalTime, totalDistance) => {
  if (!startTime) return null;
  
  let speed;
  switch (timeControlType) {
    case 'BRM':
      speed = 15;
      break;
    case 'Populaire':
      speed = 12.5;
      break;
    case 'Custom':
      if (!totalTime || !distance || !totalDistance) return new Date(startTime).toISOString();
      const timeInHours = totalTime * (distance / totalDistance);
      return new Date(new Date(startTime).getTime() + (timeInHours * 60 * 60 * 1000)).toISOString();
    default:
      speed = 15;
  }
  
  if (!distance) return new Date(startTime).toISOString();
  
  const timeInHours = distance / speed;
  return new Date(new Date(startTime).getTime() + (timeInHours * 60 * 60 * 1000)).toISOString();
};

export const validateControlPoint = (point, timeControlType) => {
  const errors = {};

  // Validate description
  if (!point.description || point.description.trim() === '') {
    errors.description = 'Description is required';
  }

  // Validate distance
  if (typeof point.distance !== 'number' && point.distance !== 0) {
    errors.distance = 'Distance must be a number';
  } else if (point.distance < 0) {
    errors.distance = 'Distance must be positive';
  }

  // Validate coordinates
  if (!point.latitude || !point.longitude) {
    errors.latitude = 'GPS coordinates are required';
  } else {
    const lat = parseFloat(point.latitude);
    const lng = parseFloat(point.longitude);
    
    if (isNaN(lat) || lat < -90 || lat > 90) {
      errors.latitude = 'Invalid latitude';
    }
    if (isNaN(lng) || lng < -180 || lng > 180) {
      errors.longitude = 'Invalid longitude';
    }
  }

  // Validate closeTime for custom time control
  if (timeControlType === 'Custom' && !point.closeTime) {
    errors.closeTime = 'Close time is required for custom time control';
  }

  return errors;
};
