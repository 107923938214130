import { useState } from 'react';

export const useRideForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        date: '',
        time: '',
        timezone: '',
        registrationClosingDate: '',
        registrationClosingTime: '',
        rideType: '',
        details: 'Details about route, equipment requirements, facilities, and special instructions can be added from the Ride Admin Panel after creation.',
        startPoint: '',
        route: '',
        distance: '',
        responsibleName: '',
        responsibleEmail: '',
        responsiblePhone: '',
        rulesAndRegulations: '',
        routeDetails: '',
        whatsappGroupUrl: ''
    });

    const handleInputChange = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const resetForm = () => {
        setFormData({
            name: '',
            date: '',
            time: '',
            registrationClosingDate: '',
            registrationClosingTime: '',
            rideType: '',
            details: 'Details about route, equipment requirements, facilities, and special instructions can be added from the Ride Admin Panel after creation.',
            startPoint: '',
            route: '',
            distance: '',
            responsibleName: '',
            responsibleEmail: '',
            responsiblePhone: '',
            rulesAndRegulations: '',
            routeDetails: '',
            whatsappGroupUrl: ''
        });
    };

    return {
        formData,
        setFormData,
        handleInputChange,
        resetForm
    };
};
