export const getTestData = (rideType) => {
    // Create ride date 7 days in the future
    const rideDate = new Date();
    rideDate.setDate(rideDate.getDate() + 7);
    const formattedRideDate = rideDate.toISOString().split('T')[0];

    // Create registration closing date 1 day before ride
    const registrationClosing = new Date(rideDate);
    registrationClosing.setDate(registrationClosing.getDate() - 1);
    const formattedRegistrationDate = registrationClosing.toISOString().split('T')[0];

    const commonData = {
        name: `Test ${rideType.charAt(0).toUpperCase() + rideType.slice(1)} Ride`,
        date: formattedRideDate,
        time: "06:00",
        timezone: "Asia/Kolkata",
        registrationClosingDate: formattedRegistrationDate,
        registrationClosingTime: "18:00",
        rideType: rideType,
        details: "Details about route, equipment requirements, facilities, and special instructions can be added from the Ride Admin Panel after creation.",
        startPoint: "Test Start Point",
        route: "https://ridewithgps.com/routes/12345678",
        distance: "",
        responsibleName: "John Doe",
        responsibleEmail: "john.doe@example.com",
        responsiblePhone: "+1234567890",
        rulesAndRegulations: "1. All riders must wear helmets\n2. Lights are mandatory\n3. Follow traffic rules",
        routeDetails: "The route starts with a flat section for the first 20km, followed by rolling hills. There are convenience stores at 30km and 60km marks.",
        whatsappGroupUrl: "https://chat.whatsapp.com/example123"
    };

    switch (rideType) {
        case 'audax':
            return {
                ...commonData,
                distance: "200"
            };
        case 'race':
            return {
                ...commonData,
                distance: "100"
            };
        case 'time-trial':
            return {
                ...commonData,
                distance: "40"
            };
        default:
            return commonData;
    }
};
