import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { showSuccessToast, showErrorToast } from "../../utils/toast";
import { createRide } from '../../utils/api';

import { useRideForm } from './hooks/useRideForm';
import { useWizardNavigation } from './hooks/useWizardNavigation';
import { useDevTools } from './hooks/useDevTools';

import Step1BasicDetails from './components/Step1BasicDetails';
import Step2RouteDetails from './components/Step2RouteDetails';
import PreviewStep from './components/PreviewStep';
import WizardProgress from './components/WizardProgress/WizardProgress';
import DevTools from './components/DevTools/DevTools';

const NewRideWizard = () => {
    const { clubId } = useParams();
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorDetails, setErrorDetails] = useState(null);

    const {
        formData,
        setFormData,
        handleInputChange,
        resetForm
    } = useRideForm();

    const {
        step,
        setStep,
        errors,
        setErrors,
        getSteps,
        handleNext,
        handlePrevious
    } = useWizardNavigation(formData);

    const { showDevTools, loadTestData } = useDevTools(setFormData, setStep, setErrors);

    const formatRideDataForAPI = (data) => {
        // Create date strings without timezone information
        const startDateTime = `${data.date}T${data.time}:00`;
        const registrationClosingDateTime = `${data.registrationClosingDate}T${data.registrationClosingTime}:00`;

        return {
            name: data.name,
            startDateTime: startDateTime,
            registrationClosingDateTime: registrationClosingDateTime,
            timezone: data.timezone,
            startPoint: data.startPoint,
            details: data.details,
            route: data.route,
            distance: parseInt(data.distance),
            responsibleName: data.responsibleName,
            responsibleEmail: data.responsibleEmail,
            responsiblePhone: data.responsiblePhone,
            rulesAndRegulations: data.rulesAndRegulations,
            routeDetails: data.routeDetails,
            whatsappGroupUrl: data.whatsappGroupUrl || undefined
        };
    };

    const handleSubmit = async () => {
        try {
            setIsSubmitting(true);
            setErrorDetails(null);
            const formattedData = formatRideDataForAPI(formData);
            await createRide(clubId, formattedData);
            showSuccessToast("Ride created successfully!");
            navigate('/owner-dashboard/rides');
        } catch (error) {
            const errorMessage = error.response?.data?.message || "Failed to create ride. Please try again.";
            const errorDetail = error.response?.data?.error || null;
            showErrorToast(errorMessage);
            setErrors({
                submit: errorMessage
            });
            setErrorDetails(errorDetail);
        } finally {
            setIsSubmitting(false);
        }
    };

    const steps = getSteps();

    return (
        <div className="max-w-3xl mx-auto p-6">
            <WizardProgress steps={steps} currentStep={step} />

            <div className="bg-white rounded-lg border p-6">
                {step === 1 && (
                    <Step1BasicDetails
                        formData={formData}
                        handleInputChange={handleInputChange}
                        errors={errors}
                    />
                )}
                {step === 2 && (
                    <Step2RouteDetails
                        formData={formData}
                        handleInputChange={handleInputChange}
                        errors={errors}
                    />
                )}
                {step === 3 && (
                    <PreviewStep formData={formData} />
                )}
            </div>

            <div className="mt-6 flex justify-between">
                <button
                    onClick={handlePrevious}
                    className={`px-4 py-2 border rounded-md hover:bg-gray-50 ${step === 1 ? 'invisible' : ''}`}
                    disabled={isSubmitting}
                >
                    Previous
                </button>
                <button
                    onClick={() => handleNext(handleSubmit)}
                    className={`px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:opacity-50 disabled:cursor-not-allowed`}
                    disabled={isSubmitting}
                >
                    {step === steps.length ? (isSubmitting ? 'Creating...' : 'Create Ride') : 'Next'}
                </button>
            </div>

            {(errors.submit || errorDetails) && (
                <div className="mt-4 p-4 bg-red-50 border border-red-200 rounded-md">
                    {errors.submit && <p className="text-red-600 font-medium">{errors.submit}</p>}
                    {errorDetails && <p className="text-red-500 mt-2 text-sm">{errorDetails}</p>}
                </div>
            )}

            {Object.keys(errors).length > 0 && errors.submit === undefined && (
                <div className="mt-4 p-4 bg-red-50 border border-red-200 rounded-md">
                    <h4 className="text-red-700 font-medium mb-2">Please fix the following errors:</h4>
                    <ul className="list-disc list-inside text-sm text-red-600">
                        {Object.values(errors).map((error, index) => (
                            <li key={index}>{error}</li>
                        ))}
                    </ul>
                </div>
            )}

            {showDevTools && (
                <DevTools loadTestData={loadTestData} resetForm={resetForm} />
            )}
        </div>
    );
};

export default NewRideWizard;
