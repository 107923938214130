import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../../config/axiosConfig';
import AdminLayout from '../AdminLayout';

function RegisterRiderByURN() {
 const { rideId } = useParams();
 const [urn, setUrn] = useState('');
 const [error, setError] = useState('');
 const [success, setSuccess] = useState('');
 const [loading, setLoading] = useState(false);

 const handleRegister = async () => {
   if (!urn) {
     setError('Please enter a URN number.');
     return;
   }

   setLoading(true);
   setError('');

   try {
     const response = await axios.post(`/rides/${rideId}/admin-register/${urn}`);

     if (response.status === 200) {
       setSuccess('Rider registered successfully!');
       setUrn('');
     } else {
       setError('Failed to register rider.');
     }
   } catch (err) {
     console.error('Error registering rider:', err);
     setError(err.response?.data?.message || 'Error registering rider.');
   } finally {
     setLoading(false);
   }
 };

 return (
   <AdminLayout title="Register Rider by URN">
     <div className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-lg">
       {error && <p className='text-red-500 text-sm mb-4'>{error}</p>}
       {success && <p className='text-green-500 text-sm mb-4'>{success}</p>}
       
       <div className='mb-4'>
         <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='urn'>
           URN Number
         </label>
         <input
           type='text'
           id='urn'
           name='urn'
           value={urn}
           onChange={(e) => setUrn(e.target.value)}
           className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
         />
       </div>
       
       <button
         onClick={handleRegister}
         disabled={loading}
         className='w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
       >
         {loading ? 'Registering...' : 'Register'}
       </button>
     </div>
   </AdminLayout>
 );
}

export default RegisterRiderByURN;
