import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from '../../../config/axiosConfig';
import AdminLayout from '../AdminLayout';
import AdminAccessCheck from '../AdminAccessCheck';
import { AlertDialog, AlertDialogContent, AlertDialogHeader, AlertDialogTitle, AlertDialogDescription, AlertDialogFooter, AlertDialogAction, AlertDialogCancel } from "../../ui/alert-dialog";

function RegisterRiderForRace() {
    const { rideId } = useParams();
    const queryClient = useQueryClient();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        urn: '',
        categoryId: ''
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const mutation = useMutation({
        mutationFn: async (data) => {
            return axios.post(`/rides/${rideId}/admin-register-race`, data);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['rideDetails', rideId]);
            setSuccess('Rider registered successfully!');
            setFormData({
                name: '',
                email: '',
                phone: '',
                urn: '',
                categoryId: ''
            });
            setTimeout(() => setSuccess(''), 3000);
        },
        onError: (error) => {
            setError(error.response?.data?.message || 'Failed to register rider');
            setTimeout(() => setError(''), 3000);
        }
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.name || !formData.email || !formData.urn) {
            setError('Please fill in all required fields');
            return;
        }
        setIsDialogOpen(true);
    };

    const confirmRegistration = () => {
        mutation.mutate(formData);
        setIsDialogOpen(false);
    };

    return (
        <AdminLayout title="Register New Rider">
            <div className="max-w-2xl mx-auto">
                {error && (
                    <div className="mb-4 p-4 bg-red-50 border-l-4 border-red-500 text-red-700">
                        {error}
                    </div>
                )}
                {success && (
                    <div className="mb-4 p-4 bg-green-50 border-l-4 border-green-500 text-green-700">
                        {success}
                    </div>
                )}

                <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Name *</label>
                        <input
                            type="text"
                            value={formData.name}
                            onChange={(e) => setFormData({...formData, name: e.target.value})}
                            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                            required
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700">Email *</label>
                        <input
                            type="email"
                            value={formData.email}
                            onChange={(e) => setFormData({...formData, email: e.target.value})}
                            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                            required
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700">Phone</label>
                        <input
                            type="tel"
                            value={formData.phone}
                            onChange={(e) => setFormData({...formData, phone: e.target.value})}
                            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                        />
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700">URN *</label>
                        <input
                            type="text"
                            value={formData.urn}
                            onChange={(e) => setFormData({...formData, urn: e.target.value})}
                            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                            required
                        />
                    </div>

                    <button
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        Register Rider
                    </button>
                </form>

                <AlertDialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                    <AlertDialogContent>
                        <AlertDialogHeader>
                            <AlertDialogTitle>Confirm Registration</AlertDialogTitle>
                            <AlertDialogDescription>
                                Are you sure you want to register this rider?
                                <div className="mt-2">
                                    <p><strong>Name:</strong> {formData.name}</p>
                                    <p><strong>Email:</strong> {formData.email}</p>
                                    <p><strong>URN:</strong> {formData.urn}</p>
                                </div>
                            </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction onClick={confirmRegistration}>
                                Confirm Registration
                            </AlertDialogAction>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialog>
            </div>
        </AdminLayout>
    );
}

export default RegisterRiderForRace;
