import React from 'react';
import { ZoomIn, ZoomOut, Trash2, Upload } from 'lucide-react';
import { ImageIcon } from 'lucide-react';

const ImagePreview = ({ previewUrl, isZoomed, setIsZoomed, handleRemoveImage, setEditing }) => {
    return (
        <div className="relative group">
            <div className="relative w-full" style={{ paddingBottom: '56.25%' }}>
                <div className="absolute inset-0">
                    {previewUrl ? (
                        <img
                            src={previewUrl}
                            alt="Current cover"
                            className={`w-full h-full object-cover transition-all duration-300 rounded-lg
                            ${isZoomed ? 'scale-150' : 'scale-100'}`}
                        />
                    ) : (
                        <div className="w-full h-full bg-gray-100 flex items-center justify-center rounded-lg">
                            <ImageIcon className="h-12 w-12 text-gray-400" />
                        </div>
                    )}
                    <div className="absolute inset-0 bg-black/0 group-hover:bg-black/30 transition-all duration-300 rounded-lg">
                        <div className="absolute bottom-4 right-4 flex space-x-2 opacity-0 group-hover:opacity-100 transition-all duration-300">
                            {previewUrl && (
                                <>
                                    <button
                                        onClick={() => setIsZoomed(!isZoomed)}
                                        className="bg-white/90 hover:bg-white text-gray-800 p-2 rounded-lg shadow-lg transition-transform hover:-translate-y-1"
                                    >
                                        {isZoomed ? <ZoomOut className="h-5 w-5" /> : <ZoomIn className="h-5 w-5" />}
                                    </button>
                                    <button
                                        onClick={handleRemoveImage}
                                        className="bg-red-500/90 hover:bg-red-500 text-white p-2 rounded-lg shadow-lg transition-transform hover:-translate-y-1"
                                    >
                                        <Trash2 className="h-5 w-5" />
                                    </button>
                                </>
                            )}
                            <button
                                onClick={() => setEditing(true)}
                                className="bg-white/90 hover:bg-white text-gray-800 p-2 rounded-lg shadow-lg transition-transform hover:-translate-y-1 flex items-center space-x-2"
                            >
                                <Upload className="h-5 w-5" />
                                <span>{previewUrl ? 'Change Image' : 'Add Image'}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImagePreview;
