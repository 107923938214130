import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import NavBar from './components/NavBar';
import Breadcrumbs from './components/Breadcrumbs';
import Footer from './components/Footer';
import PrivateRoutes from './components/PrivateRoutes';
import Home from './components/Home/Home';
import About from './components/About';
import RideList from './components/RideList/index';
import RideDetails from './components/RideDetails';
import UserRegistration from './components/UserRegistration';
import Login from './components/Login';
import ResetPassword from './components/ResetPassword';
import ForgotPassword from './components/ResetPassword';
import Dashboard from './components/Dashboard';
import ClubOwnerDashboard from './components/ClubOwnerDashboard';
import ClubOwnerRides from './components/ClubOwnerDashboard/Rides';
import MyRides from './components/MyRides';
import Profile from './components/Profile';
import Settings from './components/Settings';
import AllChatsPage from './components/Chat/allChats';
import Clubs from './components/Clubs';
import ClubDetails from './components/Clubs/ClubDetails';
import LeaderboardDetails from './components/Clubs/LeaderboardDetails';
import NewClub from './components/NewClub';
import ClubRides from './components/ClubRides/ClubRides';
import ClubPaymentsOnboarding from './components/ClubOwnerDashboard/ClubPaymentsOnboarding';
import SeriesParticipation from './components/SeriesParticipation';
import LiveRidersInfo from './components/RideAdmin/LiveRidersInfo';
import MasterAdminPage from './components/RideAdmin/MasterAdminPage';
import RideDetailsAdmin from './components/RideAdmin/RideInformationEditor';
import RideDescriptionEditor from './components/RideAdmin/RideDetailsEditor';
import RouteDescription from './components/RideAdmin/RouteDescription';
import RideVisibility from './components/RideAdmin/RideVisibility';
import CoverImageManager from './components/RideAdmin/CoverImage/index';
import ResponsiblePersonEditor from './components/RideAdmin/ResponsiblePerson/ResponsiblePersonEditor';
import RulesAndRegulationsEditor from './components/RideAdmin/RulesAndRegulationsEditor';
import RideCategories from './components/RideAdmin/Categories/RideCategories';
import RiderCategoryManager from './components/RideAdmin/Categories/RiderCategoryManager';
import AdminRidersList from './components/RideAdmin/AdminRidersList';
import ControlPointsEditor from './components/RideAdmin/ControlPointsEditor/ControlPointsEditor';
import RaceResults from './components/RideAdmin/RaceManagement/RaceResults';
import RaceStandings from './components/RideAdmin/RaceManagement/RaceStandings';
import LiveTracking from './components/RideAdmin/RaceManagement/LiveTracking';
import BirdsEyeView from './components/RideAdmin/RaceManagement/BirdsEyeView';
import RaceControlCenter from './components/RideAdmin/RaceManagement/RaceControlCenter';
import MissingCPMonitor from './components/RideAdmin/RaceManagement/MissingCPMonitor';
import RegisterRiderByURN from "./components/RideAdmin/RaceManagement/RegisterRiderByURN"; import RegisterRiderForRace from "./components/RideAdmin/RaceManagement/RegisterRiderForRace";
import RidePayment from "./components/RideAdmin/RidePayment";
import OfflinePayments from './components/RideAdmin/PaymentManagement/OfflinePayments';
import PaymentHistory from './components/RideAdmin/PaymentHistory';
import PaymentsDashboard from './components/payments/PaymentsDashboard';
import RegistrationDetails from './components/RideAdmin/RegistrationManagement/RegistrationDetails';
import OurMission from './components/OurMission';
import MeetOurTeam from './components/MeetOurTeam';
import ExploreOurHistory from './components/ExploreOurHistory';
import Contact from './components/Contact';
import Faq from './components/Faq';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import RulesAndRegulations from './components/RulesAndRegulations';
import NewRideWizard from './components/NewRideWizard/NewRideWizard';
import PublicProfile from './components/PublicProfile';
import { AuthProvider } from './context/AuthContext';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [step, setStep] = useState('forgotPassword');

  useEffect(() => {
    const isLoggedIn = !!localStorage.getItem('isLoggedIn');
    if (isLoggedIn && window.location.pathname === '/') {
      navigate('/dashboard');
    }

    const updateTitle = () => {
      let title = "Ultra Rides";
      const path = location.pathname;
      const titleMappings = {
        "/": "Home | Ultra Rides",
        "/about": "About Us | Ultra Rides",
        "/rides": "Rides | Ultra Rides",
        "/create-account": "Create Account | Ultra Rides",
        "/login": "Login | Ultra Rides",
        "/reset-password": "Reset Password | Ultra Rides",
        "/forgot-password": "Forgot Password | Ultra Rides",
        "/clubs": "Clubs | Ultra Rides",
        "/payments": "Payments | Ultra Rides",
        "/offline-payments": "Offline Payments | Ultra Rides",
        "/payment-history": "Payment History | Ultra Rides",
        "/dashboard": "Dashboard | Ultra Rides",
        "/owner-dashboard": "Owner Dashboard | Ultra Rides",
        "/myrides": "My Rides | Ultra Rides",
        "/profile": "Profile | Ultra Rides",
        "/settings": "Settings | Ultra Rides",
        "/chat": "Chats | Ultra Rides",
        "/our-mission": "Our Mission | Ultra Rides",
        "/meet-our-team": "Meet Our Team | Ultra Rides",
        "/history": "Our History | Ultra Rides",
        "/contact": "Contact Us | Ultra Rides",
        "/faq": "FAQ | Ultra Rides",
        "/privacy-policy": "Privacy Policy | Ultra Rides",
        "/terms-of-service": "Terms of Service | Ultra Rides",
        "/rules-and-regulations": "Rules & Regulations | Ultra Rides",
        "/rides/:rideId/admin/responsible-person": "Edit Responsible Person | Ultra Rides",
        "/rides/:rideId/admin/rules-and-regulations": "Edit Rules & Regulations | Ultra Rides",
      };

      if (titleMappings[path]) {
        title = titleMappings[path];
      } else if (path.startsWith("/rides/")) {
        const rideId = path.split("/")[2];
        if (path.includes("/admin/race-")) {
          const section = path.split("race-")[1];
          title = `Race ${section.charAt(0).toUpperCase() + section.slice(1)} | ${rideId} | Ultra Rides`;
        } else {
          title = `Ride Details | ${rideId} | Ultra Rides`;
        }
      } else if (path.startsWith("/clubs/")) {
        const clubId = path.split("/")[2];
        if (path.includes("/leaderboard")) {
          title = `Club Leaderboard | ${clubId} | Ultra Rides`;
        } else {
          title = `Club Details | ${clubId} | Ultra Rides`;
        }
      }
      document.title = title;
    };

    updateTitle();
  }, [navigate, location]);

  const isPublicProfilePath = /^\/athletes\/\d+\/profile$/.test(location.pathname);

  return (
    <AuthProvider>
      <div className="App bg-background min-h-screen text-textPrimary flex flex-col m-0 p-0">
        <NavBar />
        {!isPublicProfilePath && <Breadcrumbs />}
        <div className="content-container pt-0 flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/rides" element={<RideList />} />
            <Route path="/create-account" element={<UserRegistration />} />
            <Route path="/rides/:rideId" element={<RideDetails />} />
            <Route path="/login" element={<Login />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/forgot-password" element={<ForgotPassword setStep={setStep} />} />
            <Route path="/rides/:rideId/admin" element={<MasterAdminPage />} />
            <Route path="/rides/:rideId/admin/details-editor" element={<RideDescriptionEditor />} />
            <Route path="/rides/:rideId/admin/cover-image" element={<CoverImageManager />} />
            <Route path="/rides/:rideId/admin/riders" element={<AdminRidersList />} />
            <Route path="/rides/:rideId/admin/registration" element={<RegistrationDetails />} />
            <Route path="/rides/:rideId/admin/control-points" element={<ControlPointsEditor />} />
            <Route path="/rides/:rideId/admin/race-results" element={<RaceResults />} />
            <Route path="/rides/:rideId/admin/race-standings" element={<RaceStandings />} />
            <Route path="/rides/:rideId/admin/live-tracking" element={<LiveTracking />} />
            <Route path="/rides/:rideId/admin/birds-eye" element={<BirdsEyeView />} />
            <Route path="/rides/:rideId/admin/race-control" element={<RaceControlCenter />} />
            <Route path="/rides/:rideId/admin/ride-payment" element={<RidePayment />} />
            <Route path="/rides/:rideId/admin/offline-payments" element={<OfflinePayments />} />
            <Route path="/rides/:rideId/admin/payment-history" element={<PaymentHistory />} />
            <Route path="/rides/:rideId/admin/ride-information" element={<RideDetailsAdmin />} />
            <Route path="/rides/:rideId/admin/route-description" element={<RouteDescription />} />
            <Route path="/rides/:rideId/admin/visibility" element={<RideVisibility />} />
            <Route path="/rides/:rideId/admin/categories" element={<RideCategories />} />
            <Route path="/rides/:rideId/admin/rider-categories" element={<RiderCategoryManager />} />
            <Route path="/rides/:rideId/admin/responsible-person" element={<ResponsiblePersonEditor />} />
            <Route path="/rides/:rideId/admin/rules-and-regulations" element={<RulesAndRegulationsEditor />} />
            <Route path="/rides/:rideId/admin/live-riders" element={<LiveRidersInfo />} />
            <Route path="/rides/:rideId/admin/missing-cp" element={<MissingCPMonitor />} />
            <Route path="/rides/:rideId/admin/register-race" element={<RegisterRiderByURN />} />            <Route path="/rides/:rideId/admin/register-rider" element={<RegisterRiderForRace />} />
            <Route path="/clubs" element={<Clubs />} />
            <Route path="/clubs/:clubId" element={<ClubDetails />} />
            <Route path="/clubs/:clubId/leaderboard" element={<LeaderboardDetails />} />
            <Route path="/clubs/:clubId/rides" element={<ClubRides />} />
            <Route path="/clubs/ClubPaymentsOnboarding" element={<ClubPaymentsOnboarding />} />
            <Route path="/athletes/:userId/profile" element={<PublicProfile />} />
            <Route path="/payments" element={<PaymentsDashboard />} />
            <Route element={<PrivateRoutes />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/owner-dashboard/rides" element={<ClubOwnerRides />} />
              <Route path="/clubs/series" element={<SeriesParticipation />} />
              <Route path="/clubs/new" element={<NewClub />} />
              <Route path="/clubs/:clubId/rides/new" element={<NewRideWizard />} />
              <Route path="/myrides" element={<MyRides />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/mychats" element={<AllChatsPage />} />
              <Route path="/owner-dashboard" element={<ClubOwnerDashboard />} />
            </Route>
            <Route path="/our-mission" element={<OurMission />} />
            <Route path="/meet-our-team" element={<MeetOurTeam />} />
            <Route path="/history" element={<ExploreOurHistory />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/rules-and-regulations" element={<RulesAndRegulations />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </AuthProvider>
  );
}

export default App;
