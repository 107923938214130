import React, { useState } from 'react';

const TimeControlSelector = ({ timeControlType, totalTime, onTimeChange, onTypeChange }) => {
    const [customTime, setCustomTime] = useState(totalTime || '');

    const handleCustomTimeChange = (e) => {
        const value = e.target.value;
        setCustomTime(value);
        onTimeChange(value);
    };

    return (
        <div className="bg-white p-4 rounded-lg shadow">
            <label className="block text-sm font-medium text-gray-700 mb-2">
                Time Control Type:
            </label>
            <div className="flex flex-wrap gap-2">
                <button
                    onClick={() => onTypeChange('BRM')}
                    className={`px-4 py-2 rounded-lg ${
                        timeControlType === 'BRM'
                            ? 'bg-blue-500 text-white'
                            : 'bg-gray-200 text-gray-700'
                    }`}
                >
                    BRM (15 kmph)
                </button>
                <button
                    onClick={() => onTypeChange('Populaire')}
                    className={`px-4 py-2 rounded-lg ${
                        timeControlType === 'Populaire'
                            ? 'bg-blue-500 text-white'
                            : 'bg-gray-200 text-gray-700'
                    }`}
                >
                    Populaire (12.5 kmph)
                </button>
                <button
                    onClick={() => onTypeChange('Custom')}
                    className={`px-4 py-2 rounded-lg ${
                        timeControlType === 'Custom'
                            ? 'bg-blue-500 text-white'
                            : 'bg-gray-200 text-gray-700'
                    }`}
                >
                    Custom Time
                </button>
            </div>
            {timeControlType === 'Custom' && (
                <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Total Time (hours):
                    </label>
                    <input
                        type="number"
                        value={customTime}
                        onChange={handleCustomTimeChange}
                        className="w-full px-3 py-2 border rounded-md"
                        placeholder="Enter total time in hours"
                        min="1"
                        step="0.5"
                    />
                </div>
            )}
            <p className="mt-2 text-sm text-gray-500">
                {timeControlType === 'Custom' 
                    ? 'Control points will close proportionally based on their distance'
                    : 'Changing this will recalculate the control point closing times.'}
            </p>
        </div>
    );
};

export default TimeControlSelector;
