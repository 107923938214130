import React from 'react';

const ImagePreviewWithInfo = ({ previewUrl, imageInfo, isUploading, uploadProgress, newImage }) => {
    return (
        <div className="space-y-4">
            <div className="relative w-full" style={{ paddingBottom: '56.25%' }}>
                <div className="absolute inset-0">
                    <img
                        src={previewUrl}
                        alt="Preview"
                        className="w-full h-full object-cover rounded-lg"
                    />
                    {isUploading && (
                        <div className="absolute inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center rounded-lg">
                            <div className="w-3/4 max-w-md space-y-3">
                                <div className="flex items-center justify-between text-white">
                                    <span>Uploading your image...</span>
                                    <span>{uploadProgress}%</span>
                                </div>
                                <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                                    <div
                                        style={{ width: `${uploadProgress}%` }}
                                        className="h-full bg-blue-500 transition-all duration-300"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {imageInfo && (
                <div className="bg-gray-50 rounded-lg p-4 grid grid-cols-2 md:grid-cols-4 gap-4">
                    <div className="text-center">
                        <p className="text-sm text-gray-500">Dimensions</p>
                        <p className="font-medium">{imageInfo.width} x {imageInfo.height}</p>
                    </div>
                    <div className="text-center">
                        <p className="text-sm text-gray-500">Aspect Ratio</p>
                        <p className="font-medium">{imageInfo.aspectRatio}</p>
                    </div>
                    <div className="text-center">
                        <p className="text-sm text-gray-500">File Size</p>
                        <p className="font-medium">{imageInfo.size} MB</p>
                    </div>
                    <div className="text-center">
                        <p className="text-sm text-gray-500">Format</p>
                        <p className="font-medium">{newImage?.type.split('/')[1].toUpperCase()}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImagePreviewWithInfo;
