export const validateStep = (stepNumber, formData) => {
    const errors = {};

    if (stepNumber === 1) {
        if (!formData.name?.trim()) {
            errors.name = 'Ride name is required';
        }
        if (!formData.date) {
            errors.date = 'Date is required';
        } else {
            const selectedDate = new Date(formData.date + 'T00:00:00');
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            if (selectedDate < today) {
                errors.date = 'Date cannot be in the past';
            }
        }
        
        if (!formData.time) {
            errors.time = 'Start time is required';
        }
        
        if (!formData.registrationClosingDate) {
            errors.registrationClosingDate = 'Registration closing date is required';
        }
        
        if (!formData.registrationClosingTime) {
            errors.registrationClosingTime = 'Registration closing time is required';
        }
        
        if (!formData.timezone) {
            errors.timezone = 'Timezone is required';
        }
        
        if (!formData.rideType) {
            errors.rideType = 'Ride type is required';
        }
    }

    if (stepNumber === 2) {
        if (!formData.startPoint?.trim()) {
            errors.startPoint = 'Start point is required';
        }
        
        if (!formData.distance) {
            errors.distance = 'Distance is required';
        } else if (parseFloat(formData.distance) <= 0) {
            errors.distance = 'Distance must be greater than 0';
        }
        
        if (formData.route && !formData.route.includes('ridewithgps.com/routes/')) {
            errors.route = 'Must be a valid Ride with GPS route URL';
        }
    }
    
    return errors;
};
